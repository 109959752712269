import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useStore} from '../services/store';
import { useNavigate, useParams } from 'react-router-dom';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description, confirmButtonText, confirmButtonColor }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Avbryt</Button>
        <Button onClick={onConfirm} color={confirmButtonColor} autoFocus>{confirmButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const CrudButtons = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});
  const buttonAction = useStore(state => state.buttonAction);
  const setButtonAction = useStore(state => state.setButtonAction);

  const handleOpenDialog = (type) => {
    const configs = {
      new: {
        title: "Opprett ny",
        description: "Opprett ny post?",
        confirmButtonText: "Lag ny",
        confirmButtonColor: "primary",
        onConfirm: () => updateButtonAction("new", true)
      },
      save: {
        title: "Lagre",
        description: "Er du sikker på at du vil lagre endringene? Dette kan ikke gjøres om.",
        confirmButtonText: "Lagre",
        confirmButtonColor: "primary",
        onConfirm: () => updateButtonAction("save", true)
      },      
      revert: {
        title: "Angre endringer",
        description: "Er du sikker på at du vil tilbakestille skjemaet? Alle ulagra endringer vil gå tapt.",
        confirmButtonText: "Tilbakestill",
        confirmButtonColor: "primary",
        onConfirm: () => updateButtonAction("revert", true)
      },
      delete: {
        title: "Slett skjemaet",
        description: "Er du sikker på at du vil slette skjemaet? Dette kan ikke gjøres om.",
        confirmButtonText: "Slett",
        confirmButtonColor: "error",
        onConfirm: () => updateButtonAction("delete", true)
      }
      // Add additional button configurations here if needed
    };

    setOpenDialog(true);
    setDialogConfig(configs[type] || {});
  };

  const updateButtonAction = (actionName, value) => {
    setOpenDialog(false);
    const updatedAction = { ...buttonAction, [actionName]: value };
    setButtonAction(updatedAction);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <ButtonGroup size="small" variant="outlined" aria-label="text button group">
        <Button onClick={() => { updateButtonAction("new", true); }}>Ny</Button>
        <Button onClick={() => { updateButtonAction("save", true); }}>Lagre</Button>
        <Button onClick={() => { handleOpenDialog('revert'); }}>Angre</Button>
        <Button color="error" onClick={() => { handleOpenDialog('delete'); }}>Slett</Button>
      </ButtonGroup>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={dialogConfig.onConfirm}
        title={dialogConfig.title}
        description={dialogConfig.description}
        confirmButtonText={dialogConfig.confirmButtonText}
        confirmButtonColor={dialogConfig.confirmButtonColor}
      />
    </>
  );
};

export const NavButtons = () => {
  const filteredSortedIds = useStore(state => state.filteredSortedIds);

  let { id } = useParams();
  const navigate = useNavigate();

  const selectNextRow = () => {
    const currentIndex = filteredSortedIds.indexOf(id || 0);
    const nextIndex = currentIndex + 1 === filteredSortedIds.length ? 0 : currentIndex + 1;  
    navigate(filteredSortedIds[nextIndex]);  
  };

  const selectPreviousRow = () => {
    const currentIndex = filteredSortedIds.indexOf(id || 0);
    const previousIndex = currentIndex - 1 < 0 ? filteredSortedIds.length - 1 : currentIndex - 1;  
    navigate(filteredSortedIds[previousIndex]);  
  };

  return (
    <>
      <ButtonGroup size="small" variant="outlined" aria-label="text button group">
        <Button color="primary" variant="contained" onClick={() => {selectPreviousRow()}}>&lt;</Button>
        <Button color="primary" variant="contained" onClick={() => {selectNextRow()}}>&gt;</Button>
      </ButtonGroup>   
    </>
  );
};