import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { LoadingMessage } from '../components/Loading';
import { useStore } from '../services/store';
import { makeRequest } from '../services/query';
import { EngasjementList, EngasjementModal } from './Engasjement';
import useCrudActions from '../components/crudActions';
import { 
  InputText, 
  InputTextMulti, 
  InputDate
} from '../components/InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';

const AktoerIngen = () => (
  <Typography paragraph>
    Ingen aktør er valgt. Opprett ny, eller velg eksisterende aktør i tabellen.
  </Typography>
);

function RolleForm({selectedRowData, setSelectedRowData, roller}) {
  const rolle = selectedRowData.roller || {}
  const selectedItems = roller.filter(r => rolle[r.value]);

  const handleChange = (_, newValue) => {
    const newObj = roller.reduce((acc, curr) => ({...acc, [curr.value]: newValue.includes(curr)}), {});
    setSelectedRowData({...selectedRowData, roller: newObj});
  }

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={roller}
      getOptionLabel={(option) => option.label}
      value={selectedItems}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Roller" />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
        ))
      }
    />
  );
}

export default function  AktoerEdit ({ apiTable, editTable, apiId }) {
  console.log("Render AktørEdit")

  const selectedAktoerUUID = useStore(state => state.selectedAktoerUUID);
  const setSelectedAktoerUUID = useStore(state => state.setSelectedAktoerUUID);
  const [loading, setLoading] = useState(true);
  const [roller, setRoller] = useState([]);
  const [engasjement, setEngasjement] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [engasjementData, setEngasjementData] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({}); 

  let { id } = useParams();
  const navigate = useNavigate();

  const getListData = async () => {
    setLoading(true);
    try {
      const rolleList = await makeRequest('get', 'list_rolle', {}, {});
      setRoller(rolleList);
      const engasjementList = await makeRequest('get', `v_engasjement?ansatt_id=eq.${selectedAktoerUUID}`, {}, {});
      setEngasjement(engasjementList);    
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
    }
    setLoading(false);
  };

  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        console.log('Running handleRowselected')
        console.log(uuid)
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        setSelectedRowData(rowData[0] || {});
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }      
    } else {
      setSelectedRowData({});
    }
    setLoading(false);
  };

  //Cleaning data before update
  const { navn, ...cleanedRowData } = selectedRowData;
  // Retrieve the CRUD functions from the hook
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedAktoerUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedAktoerUUID,
    apiTable: editTable,
    navigate: navigate,   
  });

  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedAktoerUUID(id);
      handleRowSelected(id);
      getListData();      
    } else if(selectedAktoerUUID){
      navigate(selectedAktoerUUID);
      setLoading(false);
    }
  }, [id]);

  const nyttEngasjement = () => {
    setEngasjementData({
      ansatt_id: selectedAktoerUUID,
      ansatt_kode: selectedRowData.ansatt_id
      })
    setIsModalOpen(true)
  };

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  };

  if(!id){
    return <AktoerIngen />
  };

  return(
    <div>
      <Grid container spacing={2} >
        <Grid xs={12} sm={12} md={6} lg={6} xl={4}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Aktør</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >            
                <Grid xs={12} sm={6} md={6} lg={4} xl={5}>
                  <InputText
                    name="fornavn"
                    label="Fornavn"
                    value={selectedRowData.fornavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={5}>
                  <InputText
                    name="etternavn"
                    label="Etternavn"
                    value={selectedRowData.etternavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={4} sm={4} md={6} lg={4} xl={2}>
                  <InputText
                    name="initial"
                    label="Initialer"
                    value={selectedRowData.initial}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={8} sm={8} md={6} lg={4} xl={3}>
                  <InputDate
                    name="foedt_dato"
                    label="Født"
                    value={selectedRowData.foedt_dato}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>                         
                <Grid xs={12} sm={6} md={6} lg={4} xl={3}>
                  <InputText
                    name="epost"
                    label="E-post"
                    value={selectedRowData.epost}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3}>
                  <InputText
                    name="telefon"
                    label="Telefon"
                    value={selectedRowData.telefon}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputTextMulti
                    name="adresse"
                    label="Adresse"
                    value={selectedRowData.adresse}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>
              </Grid> 
            </form>
          </Paper>
        </Grid>
        <Grid sm={12} md={6} lg={6} xl={4}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Arbeidsforhold</Typography>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={12} sm={4} md={4} lg={3} xl={2}>
                  <InputText
                    name="ansattnr"
                    label="Ansattnummer"
                    value={selectedRowData.ansattnr}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>              
                <Grid xs={12} sm={8} md={8} lg={9} xl={4}>
                  <InputText
                    name="avdeling"
                    label="Avdeling"
                    value={selectedRowData.avdeling}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                  <RolleForm selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} roller={roller} />
                </Grid>
              </Grid>             
          </Paper>

        </Grid>        
        <Grid xs={12} sm={12} md={6} lg={6} xl={4}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjekttilknytning</Typography>
            <Button color="primary" startIcon={<AddIcon />} onClick={() => nyttEngasjement()}>
              Nytt engasjement
            </Button>
            <EngasjementModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} engasjementData={engasjementData} />
            <EngasjementList engasjement={engasjement} />
          </Paper>

          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Historie</Typography>

          </Paper>

        </Grid>
      </Grid>
    </div>
  );
};
