import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar, nbNO } from '@mui/x-data-grid';
import _ from 'lodash'; 
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/Add';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import useCrudActions from '../components/crudActions';
import { ChartTabs } from './PersonalChart';
import { EngasjementModal } from './Engasjement';
import { RolleModal } from '../components/Modals';
import { LoadingMessage } from '../components/Loading';
import { 
  InputText, 
  InputTextMulti, 
  SingleSelect,
  MultiSelect,
  InputKroner,
  InputDate
} from './InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';


const SesongIngen = () => (
  <Typography paragraph>
    Ingen sesong er valgt. Opprett nytt, eller velg eksisterende sesong i tabellen.
  </Typography>
);


export default function  SesongEdit ({ apiTable, editTable, apiId }) {
  console.log("Render SesongEdit")

  const [loading, setLoading] = useState(false);
  const [prosjektledere, setProsjektledere] = useState([]);
  const [prosjekt, setProsjekt] = useState([]);
  const [ansatte, setAnsatte] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const selectedSesongUUID = useStore(state => state.selectedSesongUUID);
  const setSelectedSesongUUID = useStore(state => state.setSelectedSesongUUID); 
  const [personaloversikt, setPersonaloversikt] = useState([]);   
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [engasjementData, setEngasjementData] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({}); 
  const [openRolleModal, setOpenRolleModal] = useState(false);
  const [rolleType, setRolleType] = useState(null);

  let { id } = useParams();
  const navigate = useNavigate();

  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        setSelectedRowData(rowData[0] || {});
      } catch (error) {
        console.error('An error occurred while fetching row data: ', error);
      }      
    } else {
      setSelectedRowData({});
    }
    setLoading(false);
  };

  const getListData = async () => {
    setLoading(true);
    try {
      const [prosjektlederList, prosjektList] = await Promise.all([
        makeRequest('get', 'list_prosjektleder', {}, {}),
        makeRequest('get', 'list_prosjekt?select=value,label', {}, {}),
      ]);
      setProsjektledere(prosjektlederList);
      setProsjekt(prosjektList);
    } catch (error) {
      console.error('An error occurred while fetching list data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);
  };

  const getContentData = async () => {
    setLoading(true);
    try {
      const [ansatteList] = await Promise.all([
        makeRequest('get', `v_engasjement?prosjekt_id=eq.${id}`, {}, {})
      ]);
      setAnsatte(ansatteList);
    } catch (error) {
      console.error('An error occurred while fetching content data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedSesongUUID(id);
      handleRowSelected(id);
      getContentData();
      getListData();
    } else if(selectedSesongUUID){
      navigate(selectedSesongUUID);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!openRolleModal) { // We only want to get the data on initial render and after the modal is closed - not when it opens
      getListData();
    }
  }, [openRolleModal]);

  // Retrieve the CRUD functions from the hook
  const { utgravningsleder, prosjektnavn, saksnavn, ...cleanedRowData } = selectedRowData; 
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedSesongUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedSesongUUID,
    apiTable: editTable
  });

  const handleDelete = async (rowId) => {
    console.log('Slett ', rowId);      
    try{
      const deletedRow = await makeRequest('DELETE', `edit_engasjement?uuid=eq.${rowId}`,'{}', "Prefer: return=representation");
      console.log("Rad sletta: ", deletedRow)
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
  };

  const nyttEngasjement = () => {
    setEngasjementData({
      sesong_id: selectedSesongUUID
      })
    setIsModalOpen(true)
  }

  const endreEngasjement = (rowId) => {
    setEngasjementData({
      feltarbeid_id: rowId
      })
    setIsModalOpen(true)
  }  

  const newProsjektleder = (event) => {
    console.log("Legger til ny prosjektlederrolle.");
    setRolleType('prosjekt')
    setOpenRolleModal(true);
  };


  const ansattColumns = [
    {
      field: 'actions',
      headerName: '',
      width: 90,
      renderCell: (params) => (
        <React.Fragment>
          <IconButton 
            aria-label="Endre"
            onClick={() => endreEngasjement(params.row.uuid)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Slett"
            onClick={() => {
              if(window.confirm('Er du sikker på at du vil slette dette engasjementet?')) {
                handleDelete(params.row.uuid);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </React.Fragment>
      ),
    },      
    { field: 'status', headerName: 'Status', width: 75 },
    { field: 'ansatt_navn', headerName: 'Navn', width: 300 },
    { field: 'type_arbeid', headerName: 'Type', width: 100 },    
    { field: 'stillingsnavn', headerName: 'Stilling', width: 200 },
    { field: 'varighet_timer', headerName: 'Timer', width: 100 },  
    { field: 'ny_dato_start', headerName: 'Startdato', width: 100 },
    { field: 'ny_dato_slutt', headerName: 'Sluttdato', width: 100 },
    { field: 'merknad', headerName: 'Merknad', width: 200 },

  ];  

  const AnsattTable = () => (
    <DataGrid 
      rows={ansatte}
      columns={ansattColumns}
      editMode="row"      
      pageSize={5}
      getRowId={(row) => row['uuid']}
      //onRowSelectionModelChange={handleRowSelected}
      slots={{
        toolbar: GridToolbar,
      }}
      localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
    />
  );

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  if(!id){
    return <SesongIngen />
  }

  return(
    <div>
      <Grid container spacing={2} >
        <Grid sm={12} md={6} lg={6}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Sesongsinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={4} sm={4} md={4} lg={4} xl={2}>
                  <InputText
                    name="kode"
                    label="Kode"
                    value={selectedRowData.kode}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />                
                </Grid>
                <Grid xs={8} sm={4} md={8} lg={4} xl={6}>
                  <InputText
                    name="navn"
                    label="Navn"
                    value={selectedRowData.navn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={4} md={12} lg={4} xl={4}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">                 
                    <MultiSelect
                      name="utgravningsleder_uuid"
                      label="Utgravningsleder"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={prosjektledere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til prosjektleder"
                      onClick={newProsjektleder}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>                
                </Grid>
                <Grid xs={6} sm={6} md={4}>
                  <InputDate
                    name="registrert_dato"
                    label="Dato registrert"
                    value={selectedRowData.registrert_dato}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={6} sm={6} md={4}>
                  <InputDate
                    name="godkjent_dato"
                    label="Dato godkjent"
                    value={selectedRowData.godkjent_dato}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={6} sm={6} md={4}>
                  <InputDate
                    name="oensket_oppstart"
                    label="Planlagt oppstart"
                    value={selectedRowData.oensket_oppstart}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={6} sm={6} md={4}>
                  <InputDate
                    name="feltarbeid_avslutta"
                    label="Arbeid avslutta i felt"
                    value={selectedRowData.feltarbeid_avslutta}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>                
                <Grid xs={12} sm={6} md={8}>
                  <InputText
                    name="fornminnetype"
                    label="Fornminnetype"
                    value={selectedRowData.fornminnetype}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputTextMulti
                    name="feltressurser"
                    label="Feltressurser"
                    value={selectedRowData.feltressurser}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={3}
                  />                   
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputTextMulti
                    name="merknad"
                    label="Kommentar"
                    value={selectedRowData.merknad}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>                  
              </Grid> 
             
            </form>
          </Paper>

          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Personaloversikt</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >            
                <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
                  <InputText
                    name="personellbehov"
                    label="Personalbehov"
                    value={selectedRowData.personellbehov}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />                
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Button color="primary" startIcon={<AddIcon />} onClick={() => nyttEngasjement()}>
                    Nytt engasjement
                  </Button>
                  <EngasjementModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} engasjementData={engasjementData} />
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ChartTabs id={id} />

                </Grid>                 
              </Grid> 
            </form>
          </Paper>
        </Grid>          
        <Grid sm={12} md={6} lg={6}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Ansatte</Typography>
            <AnsattTable />
          </Paper>

        </Grid>           
        <Grid sm={12} md={6} lg={6} xl={3}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Administrativt</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="prosjekt_id"
                      label="Prosjekt"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={prosjekt}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Prosjekt/${selectedRowData.prosjekt_id}`)}>
                      Åpne
                    </Button>                    
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={12} lg={6} xl={6}>

                </Grid>
                <Grid xs={12} sm={6} md={12} lg={6} xl={6}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="innmeldt_av"
                      label="Innmeldt av"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={prosjektledere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til prosjektleder"
                      onClick={newProsjektleder}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>                                                                       
              </Grid> 
            </form>
          </Paper>
        </Grid>
        <Grid sm={12} md={6} lg={6} xl={3}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>GIS</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputKroner
                    name="leige_tps_gps"
                    label="Leie av TPS/GPS"
                    value={selectedRowData.leige_tps_gps}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  /> 
                </Grid>
                <Grid xs={12} sm={6} md={12} lg={6} xl={6}>
                  <InputKroner
                    name="teknopakke_leige"
                    label="Leie av teknopakke"
                    value={selectedRowData.teknopakke_leige}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>                           
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputDate
                    name="avlevert_gis"
                    label="Dato avlevert"
                    value={selectedRowData.avlevert_gis}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>                                            
              </Grid> 
            </form>
          </Paper>
        </Grid>
      </Grid>
      <RolleModal
        rolle={rolleType}
        open={openRolleModal}
        setOpen={setOpenRolleModal}
      />         
    </div>
  );
};          

