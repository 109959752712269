import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const steps = [
  {
    label: 'Uttalelse',
    description: `Uttalesle med... iht. forskrift 'link' ... `,
  },
  {
    label: 'Prosjektplan',
    description:
      'Prosjektplan sendes NN og inneholder ... Denne må sendes før NNN.',
  },
  {
    label: 'Budsjett',
    description: `Budsjett med XYZ sendes innen .. før NN .`,
  },
];

export function SakStepper( {newProject}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption"></Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    { 'Send inn'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Gå tilbake
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Prosjektet er klart for oppstart</Typography>
          <Button color="primary" startIcon={<AddIcon />} onClick={newProject} sx={{ mt: 1, mr: 1 }}>
            Opprett prosjekt
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Nullstill
          </Button>
        </Paper>
      )}
    </Box>
  );
}

export function RelSak ({ sakData, navigate }) {
  console.log(`RelSak running with data: ${sakData}`)
  console.log(sakData)

  return ( 
    <>
      <Typography variant='body1' style={{ fontWeight: 'bold' }}>{sakData.saksnummer}: {sakData.saksnavn}</Typography>
      <Typography variant='body1' style={{ fontStyle: 'italic'}}>Type: {sakData.paragraf}</Typography>
      <Typography variant='body1' style={{ fontStyle: 'italic'}}>Saksbehandler: {sakData.saksbehandler}</Typography>
      <Typography variant='body1' style={{ fontStyle: 'italic'}}>Inn-dato: {sakData.sak_innkommet}</Typography>
      <Typography component="div" variant='body1' style={{ fontStyle: 'italic'}}>Frister: </Typography>

      {sakData.frist?.map((obj, index) => (
        <React.Fragment key={index}>
          <Box ml={2}>
            <Typography component="span" variant='body1' style={{ fontWeight: 'bold' }}>
              {obj.type.charAt(0).toUpperCase() + obj.type.slice(1) }
            </Typography>
          </Box>
          <Box ml={2}>
            <Typography component="span" variant='body1' style={{ fontStyle: 'italic'}}>
              Innen {new Date(obj.frist).toLocaleDateString()} ({obj.status})
            </Typography>
          </Box>
        </React.Fragment>
      ))}

      <Typography variant='body1' style={{ fontStyle: 'italic'}}>Kommentar: {sakData.kommentar}</Typography>
      <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Sak/${sakData.uuid}`)}>
        Åpne sak
      </Button>
    </>
  )
}