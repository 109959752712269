import React from 'react';
import { useNavigate  } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SourceIcon from '@mui/icons-material/Source';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import PersonIcon from '@mui/icons-material/Person';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import Toolbar from '@mui/material/Toolbar';
import { useStore } from '../services/store';



const NavigationDrawer = () => {

  const { drawerOpen, drawerWidth } = useStore((state) => ({
    drawerOpen: state.drawerOpen,
    drawerWidth: state.drawerWidth
  }));

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();


  const LogIn = () => (
    <ListItemButton>
      <ListItemIcon>
        <LoginIcon /> 
      </ListItemIcon>        
      <ListItemText primary="Log inn" />
    </ListItemButton>
  );

  const LogOut = () => (
    <ListItemButton onClick={() => keycloak.logout()}>
      <ListItemIcon>
        <LogoutIcon /> 
      </ListItemIcon>        
      <ListItemText primary="Log ut" />
    </ListItemButton>
  );

  return (
    <Drawer 
      open={drawerOpen} 
      sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
      variant="persistent"
      anchor="left"
    >
      <Toolbar />
      <List component="nav" aria-label="moduler">
        <ListItemButton onClick={() => navigate('/Sak')}>
          <ListItemIcon>
            <SourceIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Sak" />
        </ListItemButton>      
        <ListItemButton onClick={() => navigate('/Prosjekt')}>
          <ListItemIcon>
            <ArchitectureIcon /> 
          </ListItemIcon>
          <ListItemText primary="Prosjekt" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/Sesong')}>
          <ListItemIcon>
            <HomeRepairServiceIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Sesong" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/Aktør')}>
          <ListItemIcon>
            <PersonIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Aktør" />
        </ListItemButton>        

        <Divider variant="middle" component="li" />

        <ListItemButton onClick={() => navigate('/Postliste')}>
          <ListItemIcon>
            <AllInboxIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Postliste" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/HvemHvaHvor')}>
          <ListItemIcon>
            <NotListedLocationIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Hvem er Hvor" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/Kontakt')}>
          <ListItemIcon>
            <ContactPhoneIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Kontakt" />
        </ListItemButton>

        <Divider variant="middle" component="li" />

        <ListItemButton onClick={() => navigate('/Admin')}>
          <ListItemIcon>
            <AdminPanelSettingsIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Admin" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/Innstillinger')}>
          <ListItemIcon>
            <SettingsIcon /> 
          </ListItemIcon>        
          <ListItemText primary="Innstillinger" />
        </ListItemButton>
        <Divider variant="middle" component="li" />
        {keycloak.authenticated ? <LogOut /> : <LogIn />}
      </List>
    </Drawer>
  );
}

export default NavigationDrawer;
