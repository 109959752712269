import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash'; 
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import Chip from '@mui/material/Chip';
import { AskeladdenDraggable } from '../components/MapModal';
import { LoadingMessage } from '../components/Loading';
import { InnmeldingModal, RolleModal } from '../components/Modals';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import useCrudActions from '../components/crudActions';
import { RelSak } from './SakModules';
import { FristElement, FristModal } from '../components/Frister';
import { 
  InputText, 
  InputTextMulti, 
  SingleSelect,
  InputDate
} from '../components/InputFields';
import { 
  handleInputChange,
  handleMultiAutocompleteChange
} from '../helpers/buttonActions';

const ProsjektIngen = () => (
  <Typography paragraph>
    Inget prosjekt er valgt. Opprett nytt, eller velg eksisterende prosjekt i tabellen.
  </Typography>
);

const SesongInfo= ({sesongData, navigate}) => {
  var SesongInfoData = ''
 
  sesongData ? SesongInfoData = ( 
    <div>
      {sesongData.map((sesong, index) => (
        <Paper elevation={1} style={{ padding: '4px',}} key={index}>
          <Typography variant='body1' style={{ fontWeight: 'bold' }}>{sesong.kode} {sesong.navn}, {sesong.aar}</Typography>
          <Typography variant='body1' style={{ fontStyle: 'italic'}}>Type: {sesong.prosjekttype || 'Ikke satt'}</Typography>
          <Typography variant='body1' style={{ fontStyle: 'italic'}}>Fornminne: {sesong.fornminnetype || 'Ikke satt'}</Typography>
          <Typography variant='body1' style={{ fontStyle: 'italic'}}>Registrert: {new Date(sesong.ny_registrert_dato).toLocaleDateString() || 'Ikke satt'}</Typography>
          <Typography variant='body1' style={{ fontStyle: 'italic'}}>Oppstart: {new Date(sesong.ny_oensket_oppstart).toLocaleDateString() || 'Ikke satt'}</Typography>
          <Typography variant='body1' style={{ fontStyle: 'italic'}}>Varighet: {sesong.varighet || 'Ikke satt'}</Typography>
          <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Sesong/${sesong.uuid}`)}>
            Åpne sesong
          </Button>
        </Paper>
      ))}
    </div>
  ) : SesongInfoData = (
    <Typography variant='body1' style={{ fontWeight: 'bold' }}>Ingen sesongdata funnet.</Typography>
  );

  return SesongInfoData

};

export default function  ProsjektEdit ({apiTable, editTable, apiId}) {
  console.log("Render ProsjektEdit")

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [prosjektledere, setProsjektledere] = useState([]);
  const [tiltakshavere, setTiltakshavere] = useState([]);
  const [finansieringskilder, setFinansieringskilder] = useState([]);
  const [sak, setSak] = useState([]);
  const [sakData, setSakData] = useState([]);
  const [sesongData, setSesongData] = useState([]);
  const selectedProsjektUUID = useStore(state => state.selectedProsjektUUID);
  const setSelectedProsjektUUID = useStore(state => state.setSelectedProsjektUUID);
  const [selectedRowData, setSelectedRowData] = useState({}); 
  const [openFristModal, setOpenFristModal] = useState(false);
  const [openRolleModal, setOpenRolleModal] = useState(false);
  const [rolleType, setRolleType] = useState(null);

  let combinedAskIds = [];
  let { id } = useParams();
  const navigate = useNavigate();

  const getListData = async () => {
    setLoading(true);
    try{
      const prosjektlederList = await makeRequest('get', 'list_prosjektleder', {}, {});
      setProsjektledere(prosjektlederList);
      const tiltakshaverList = await makeRequest('get', 'list_tiltakshaver', {}, {});
      setTiltakshavere(tiltakshaverList);
      const finansieringskildeList = await makeRequest('get', 'list_finansieringskilde', {}, {});
      setFinansieringskilder(finansieringskildeList);
      const sakList = await makeRequest('get', 'list_sak?select=value,label', {}, {});
      setSak(sakList);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);
  };

  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        console.log('Running handleRowselected')
        console.log(uuid)
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        setSelectedRowData(rowData[0] || {});
        const sesongList = await makeRequest('get', `v_sesong?prosjekt_id=eq.${uuid}`, {}, {});
        setSesongData(sesongList);        
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }      
    } else {
      setSelectedRowData({});
    }
    setLoading(false);
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setSelectedRowData((prevState) => ({
      ...prevState, 
      [name]: checked
    }));
  };

  // Retrieve the CRUD functions from the hook
  const { 
    sted_admin, 
    saksnavn, 
    prosjektleder, 
    kontaktperson_tiltakshaver, 
    feltarbeid_avslutta, 
    utgravningsleder, 
    ...cleanedRowData 
  } = selectedRowData;  
  
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedProsjektUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedProsjektUUID,
    apiTable: editTable,
    navigate: navigate
  });

  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedProsjektUUID(id);
      handleRowSelected(id);
      getListData();
    } else if(selectedProsjektUUID){
      navigate(selectedProsjektUUID);
      setLoading(false);
    }
  }, [id]);  

  useEffect(() => {
    if(id){
      setSelectedProsjektUUID(id);
      handleRowSelected(id);
      getListData();
    } else if(selectedProsjektUUID){
      navigate(selectedProsjektUUID);
      setLoading(false);
    }
  }, [id]); 

  useEffect(() => {
    if (!openRolleModal) { // We only want to get the data on initial render and after the modal is closed - not when it opens
      getListData();
    }
  }, [openRolleModal]);

  const getSakData = async () => {
    try {
      setLoading(true);
      const sakDataList = await makeRequest('get', `v_sak?select=uuid,saksnavn,saksnummer,saksbehandler,paragraf,sak_inn,kommentar,frist,sted_samlet,ask_id&prosjekt_id=eq.${id}`, {}, {});
      setSakData(sakDataList);         
      setLoading(false);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
  };

  useEffect(() => {
    getSakData();
  }, [selectedRowData]);

  combinedAskIds = useMemo(() => {
    if (Array.isArray(sakData)) {
      return sakData.reduce((acc, obj) => {
        if (Array.isArray(obj.ask_id)) {
          acc = [...acc, ...obj.ask_id.filter(option => option != null)];
        }
        return acc;
      }, []);
    }
    return [];
  }, [sakData]);

  const newProsjektleder = (event) => {
    console.log("Legger til ny prosjektlederrolle.");
    setRolleType('prosjekt')
    setOpenRolleModal(true);
  };

  const newTiltakshaver = (event) => {
    console.log("Legger til ny tiltakshaverrolle.");
    setRolleType('tiltakshaver')
    setOpenRolleModal(true);
  };  

  const newSesong = (event) => {
    console.log("Åpner innmeldingsmodal");
    setOpen(true);
    //navigate('/Prosjekt');
  };

  const newFrist = (event) => {
    console.log("Legger til ny frist.");
    setOpenFristModal(true)
  };

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  if(!id){
    return <ProsjektIngen />
  }

  return(
    <div>
      <Grid container spacing={2} >
        <Grid sm={12} md={6} lg={6}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjektinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={4} sm={4} md={4} lg={4} xl={2}>
                  <InputText
                    name="prosjektkode"
                    label="Prosjektkode"
                    value={selectedRowData.prosjektkode}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={8} sm={4} md={8} lg={4} xl={6}>
                  <InputText
                    name="prosjektnavn"
                    label="Prosjektnavn"
                    value={selectedRowData.prosjektnavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>

                <Grid xs={12} sm={4} md={12} lg={4} xl={4}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="prosjektleder_uuid"
                      label="Prosjektleder"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={prosjektledere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til prosjektleder"
                      onClick={newProsjektleder}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>
               
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputTextMulti
                    name="kommentar"
                    label="Kommentar"
                    value={selectedRowData.kommentar}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>                  
              </Grid> 
             
            </form>
          </Paper>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Lokalitetsinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={12} sm={4} md={12} lg={4} xl={4}>
                  <Autocomplete
                    multiple
                    freeSolo //Allowing for user adding other Askeladden-ids
                    id="askeladden_id"
                    options={combinedAskIds}//Add wait for loading ??
                    getOptionLabel={(option) => option ? option.toString() : ''} // Dealing with postgres adding a lot of {null}-arrays            
                    value={selectedRowData?.askeladden_id ? selectedRowData.askeladden_id.filter(id => id != null) : []} // Dealing with postgres adding a lot of {null}-arrays
                    onChange={(event, newValue) => handleMultiAutocompleteChange(event, newValue, 'askeladden_id', selectedRowData, setSelectedRowData )}
                    renderOption={(props, option) => <li {...props}>{option}</li>} // Renders each option in the dropdown
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Askeladden-id" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value
                        .filter(option => option != null) // Dealing with postgres adding a lot of {null}-arrays
                        .map((option, index) => (
                        //<Chip variant="outlined" label={option.toString()} {...getTagProps({ index })} />
                        <Chip variant="outlined" label={<AskeladdenDraggable id={option} colour={"primary"} />} {...getTagProps({ index })} />
                      ))
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                </Grid>
                <Grid xs={12} sm={8} md={12} lg={8} xl={8}>
                  <Autocomplete
                    multiple
                    id="sted_samlet"
                    options={sakData?.sted_samlet?.filter((option) => option != null) || []}
                    getOptionLabel={(option) => option ? `${option.bnr}, ${option.fylke}, ${option.kommune}`: ''}
                    renderInput={(params) => <TextField {...params} label="Sted" variant="outlined" />}
                    value={selectedRowData?.sted_samlet || []}
                    onChange={(event, newValue) => handleMultiAutocompleteChange(event, newValue, 'sted_samlet', selectedRowData, setSelectedRowData)}
                    isOptionEqualToValue={(option, value) => _.isEqual(option, value)}
                  />
                </Grid>                   
              </Grid>
            </form>
          </Paper>  
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Saksdata</Typography>
            {
              (sakData && sakData.length > 0) ? sakData.map((sakItem, index) => (
                <RelSak 
                  key={index} 
                  sakData={sakItem} 
                  navigate={navigate}
                />
              )) : (
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Sak ikke funnet!</Typography>
              )
            }
          </Paper>
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={3} xl={3}>          
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Tilhørende sesong</Typography>
            <Button color="primary" startIcon={<AddIcon />} onClick={newSesong}>
              Meld inn sesong/utgravingsprosjekt
            </Button>
            <SesongInfo 
              sesongData={sesongData} 
              navigate={navigate}
            />
          </Paper>
        </Grid>
        <Grid sm={12} md={6} lg={6} xl={3}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Administrativt</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="sak_id"
                      label="Sak"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={sak}
                      optionLabel="label"
                      optionValue="value"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={4} >
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          name="rapport_ferdig"
                          checked={selectedRowData.rapport_ferdig || false}
                          onChange={handleCheckChange}
                        />
                      } 
                      name="rapport_ferdig" 
                      label="Rapport ferdig" 
                    />              
                    <FormControlLabel 
                      control={
                        <Checkbox
                          name="regnskap_signert"
                          checked={selectedRowData.regnskap_signert || false}
                          onChange={handleCheckChange}
                        />
                      } 
                      name="regnskap_signert" 
                      label="Regnskap signert" 
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                  <InputDate
                    name="dato_avsluttet"
                    label="Dato avslutta"
                    value={selectedRowData.dato_avsluttet}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="tiltakshaver_uuid"
                      label="Tiltakshaver"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={tiltakshavere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til tiltakshaver"
                      onClick={newTiltakshaver}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SingleSelect
                    name="finansieringskilde"
                    label="Finansieringskilde"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                    options={finansieringskilder}
                    optionLabel="label"
                    optionValue="value"
                  />
                </Grid>                
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputTextMulti
                    name="budsjett"
                    label="Budsjett"
                    value={selectedRowData.budsjett}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>                                            
              </Grid> 
            </form>
          </Paper>
        </Grid>
        <Grid  xs={12} md={12} lg={6} xl={12}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Frister</Typography>
            <Button color="primary" startIcon={<AddIcon />} onClick={newFrist}>
              Ny frist
            </Button>
            <FristElement 
              frister={selectedRowData?.frist || []}
              setSelectedRowData={setSelectedRowData}
            />
            <FristModal
              setSelectedRowData={setSelectedRowData}
              open={openFristModal}
              setOpen={setOpenFristModal}
            />
          </Paper>
        </Grid>           
      </Grid>
      <InnmeldingModal
        open={open}
        setOpen={setOpen}
        selectedRowData={selectedRowData}
        sakData={sakData}
        prosjektledere={prosjektledere}
      />
      <RolleModal
        rolle={rolleType}
        open={openRolleModal}
        setOpen={setOpenRolleModal}
      />       
    </div>
  );
};
