import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NumericFormat } from 'react-number-format';
import { 
  handleAutocompleteChange, 
  handleMultiAutocompleteChange,
  handleArrayChange,
  handleCheckChange
} from '../helpers/buttonActions';

export const InputText = ({ name, label, value: parentValue, onChange, onBlur, InputProps }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      id={name}
      name={name}
      value={value}
      onChange={handleLocalChange}
      onBlur={handleLocalBlur}
      InputProps={InputProps || {}}
    />
  );
};

export const InputDate = ({ name, label, value: parentValue, onChange, onBlur, InputProps }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      type="date"
      fullWidth
      id={name}
      name={name}
      value={value}
      onChange={handleLocalChange}
      onBlur={handleLocalBlur}
      InputProps={InputProps || {}}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const InputNumber = ({ name, label, value: parentValue, onChange, onBlur, InputProps, InputLabelProps }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      type="number"
      fullWidth
      id={name}
      name={name}
      value={value}
      onChange={handleLocalChange}
      onBlur={handleLocalBlur}
      InputProps={InputProps || {}}
      InputLabelProps={InputLabelProps || {}}
    />
  );
};

const NumericFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      valueIsNumericString
      suffix =" kr"
    />
  );
});

export const InputKroner = ({ name, label, value: parentValue, onChange, onBlur, InputLabelProps }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      id={name}
      name={name}
      value={value}
      onChange={handleLocalChange}
      onBlur={handleLocalBlur}
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      InputLabelProps={InputLabelProps || {}}
    />
  );
};

export const InputTextMulti = ({ name, label, value: parentValue, onChange, onBlur, rows }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      multiline
      fullWidth              
      rows={rows}
      id={name}
      name={name}
      value={value || ''}
      onChange={handleLocalChange}
      onBlur={handleLocalBlur}
    />
  );
};

export const SingleSelect = ({ name, label, options, optionLabel, optionValue, currData, setCurrData, endAdornment }) => {
  // Check if options is an array of objects or strings
  const isOptionObject = options.length > 0 && typeof options[0] === 'object';
  
  return (
    <Autocomplete
        style={{ flex: 1 }}
        id={name}
        fullWidth
        options={options}
        getOptionLabel={
          isOptionObject 
          ? (option) => option[optionLabel] 
          : (option) => option
        }
        isOptionEqualToValue={
          isOptionObject 
          ? (option, value) => option[optionValue] === value[optionValue] 
          : (option, value) => option === value
        }
        value={
          currData 
          ? (isOptionObject 
            ? options.find(option => option[optionValue] === currData[name]) 
            : currData[name]) || null 
          : null
        }
        onChange={(event, newValue) => 
          handleAutocompleteChange(event, newValue, name, currData, setCurrData)
        }
        renderInput={(params) => (
          <TextField 
            {...params} 
            label={label} 
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment} {/* Preserve other adornments */}
                  {endAdornment} {/* Pass custom adornment */}
                </React.Fragment>
              ),
            }} 
          />
        )}
        renderOption={(props, option) => <li {...props} key={option[optionValue]}>{option[optionLabel]}</li>} 
    />
  );
};

export const MultiSelect = ({ name, label, options, optionLabel, optionValue, currData, setCurrData }) => {
    return (
      <Autocomplete
        disablePortal
        multiple
        disableCloseOnSelect
        id={name}
        fullWidth
        options={options}
        getOptionLabel={(option) => option[optionLabel]}
        isOptionEqualToValue={(option, value) => option[optionValue] === value[optionValue]}             
        value={ 
          currData && currData[name] 
          ? options.filter(option => currData[name].includes(option[optionValue])) 
          : [] 
        }
        onChange={(event, newValue) => handleMultiAutocompleteChange(event, newValue, name, currData, setCurrData)}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option) => <li {...props} key={option[optionValue]}>{option[optionLabel]}</li>} 
      />
   )
};


export const MultiFree = ({ name, label, currData, setCurrData }) => {
    return (
      <Autocomplete
        disablePortal
        multiple
        freeSolo
        options={[]}
        id={name}
        fullWidth
        value={ 
          currData && currData[name] 
          ? currData[name] 
          : [] 
        }
        onChange={(event, newValue) => handleArrayChange(newValue, name, currData, setCurrData)}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
   )
};

export const CheckInput = ({ name, label, currData, setCurrData }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={ 
              currData 
              ? currData[name] 
              : false
            }
            onChange={(event) => handleCheckChange(event, name, currData, setCurrData)}
            color='primary'
          />
        }
        label={label}
      />
   )
};

export const SingleSelectLocal = ({ name, label, options, optionLabel, optionValue, currData, setCurrData, endAdornment }) => {
  // Check if options is an array of objects or strings
  const isOptionObject = options.length > 0 && typeof options[0] === 'object';

  const handleChange = (newValue) => {
    setCurrData(newValue?.value || '')
  };
  return (
    <Autocomplete
        style={{ flex: 1 }}
        id={name}
        options={options}
        getOptionLabel={
          isOptionObject 
          ? (option) => option[optionLabel] 
          : (option) => option
        }
        isOptionEqualToValue={
          isOptionObject 
          ? (option, value) => option[optionValue] === value[optionValue] 
          : (option, value) => option === value
        }
        value={
          currData 
          ? (isOptionObject 
            ? options.find(option => option[optionValue] === currData) 
            : currData) || null 
          : null
        }
        onChange={(event, newValue) => 
          handleChange(newValue)
        }
        renderInput={(params) => (
          <TextField 
            {...params} 
            label={label} 
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment} {/* Preserve other adornments */}
                  {endAdornment} {/* Pass custom adornment */}
                </React.Fragment>
              ),
            }} 
          />
        )}
        renderOption={(props, option) => <li {...props} key={option[optionValue]}>{option[optionLabel]}</li>} 
    />
  );
};