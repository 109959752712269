// store.js
import { create } from 'zustand';

export const useStore = create(set => ({
  // environmental variables
  env: {
    apiEndpoint: process.env.REACT_APP_DB_API,
    isOnline: process.env.REACT_APP_ONLINE,
    appTitle: process.env.REACT_APP_TITLE,
    kcUrl: process.env.REACT_APP_KC_URL,
    kcRealm: process.env.REACT_APP_KC_REALM,
    kcClient: process.env.REACT_APP_KC_CLIENT
  },
  setEnv: (envvar) => set({ env: envvar }),
  // Role of logged in user
  userRole: null,
  setUserRole: (data) => set({ userRole: data }),  
  // State of the lefthand menu drawer
  drawerOpen: true,
  drawerWidth: 200,
  setDrawerOpen: (isOpen) => set({ drawerOpen: isOpen }),
  // State of the Snackbar 
  snackbarOpen: false,
  setSnackbarOpen: (isOpen) => set({ snackbarOpen: isOpen }),
  snackbarData: {
    "actionType": "",
    "messageType": "",
    "messageText": "" 
  },
  setSnackbarData: (data) => set({ snackbarData: data }),
  // State of the tab-bar selecting Info/Edit/Table view in the main window
  tab: 1,
  setTab: (id) => set({ tab: id }),
  // State of the tab-bar selecting Person/Prosjekt view in the Hvem er Hvor window
  hvemTab: 0,
  setHvemTab: (id) => set({ hvemTab: id }),
  // State of the tab-bar selecting Utskrift/Tabell view in the Postliste window
  postTab: 0,
  setPostTab: (id) => set({ postTab: id }),    
  // CRUD button action trigger 
  buttonAction: {
    "new": false,
    "save": false,
    "revert": false,
    "delete": false 
  },
  setButtonAction: (action) => set({ buttonAction: action }),
  // Open or close the add new row-modal
  newModalOpen: false,
  setNewModalOpen: (isOpen) => set({ newModalOpen: isOpen }),
  // Table sorting and filtering
  dataGridState: {
    sortModel: [],
    filterModel: { items: [] },
  },
  setDataGridState: (newState) => set((state) => ({ dataGridState: { ...state.dataGridState, ...newState } })), 
  filteredSortedIds:[],
  setFilteredSortedIds: (ids) => set({filteredSortedIds: ids }),
  // Selected row in main window table tab
  selectedRowUUID: null,
  setSelectedRowUUID: (uuid) => set({ selectedRowUUID: uuid }),
  // Selected Sak 
  selectedSakUUID: null,
  setSelectedSakUUID: (uuid) => set({ selectedSakUUID: uuid }),  
  // Selected Prosjekt 
  selectedProsjektUUID: null,
  setSelectedProsjektUUID: (uuid) => set({ selectedProsjektUUID: uuid }),
  // Selected Sesong 
  selectedSesongUUID: null,
  setSelectedSesongUUID: (uuid) => set({ selectedSesongUUID: uuid }),   
  // Selected Aktør 
  selectedAktoerUUID: null,
  setSelectedAktoerUUID: (uuid) => set({ selectedAktoerUUID: uuid }),
//  // States for static lookup data
//  popData: false,
//  setPopData: (bool) => set({ popData: bool }),
//  prosjekt: [],
//  setProsjekt: (data) => set({ prosjekt: data }),
}));

export const sakStore = create(set => ({
  // Places associated with selected Sak
  //placeRows: [],
  //setPlaceRows: (rows) => set({ placeRows: rows }),
  // Is it a §10-Sak? 
  isParagraph10: false,
  setIsParagraph10: (isSet) => set({ isParagraph10: isSet }),  
}));
