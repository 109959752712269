import React, { useState, useRef } from 'react';
import { MapContainer, TileLayer, Polygon, GeoJSON , useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import PageviewIcon from '@mui/icons-material/Pageview';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import 'leaflet/dist/leaflet.css';
import '../assets/css/MapModal.css';


const raLokIcon = L.icon({
  iconUrl: '/markers/ra_ark_lok.png', // You will need to provide the path to your icon image
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open
  //shadowUrl: 'path_to_your_shadow.png', // Optional: path to shadow image
  //shadowSize: [41, 41] // Optional: size of the shadow image
});

export const AskeladdenDraggable = ({id, colour}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [raData, setRaData] = useState({});
  const nodeRef = useRef(null); // See https://github.com/react-grid-layout/react-draggable/blob/2c3aa84352ca8eaed42a9ac0b59a1187e7850b6a/README.md?plain=1#L231

  const handleOpen = async (id) => {
    try {
      //const response = await fetch(`https://kms-api.kulturminnesok.no/api/v2/search/${id}`);
      const response = await fetch(`https://api-proxy.humgis.uiocloud.no/api/v2/search/${id}`);
      const json = await response.json();
      setRaData(json);

      setModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch polygon data', error);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  // Ensure the map fits the bounds of the polygon when the data is loaded
  const ChangeMapView = ({ coords }) => {
    const map = useMap();
    if (coords.length > 0) {
      const bounds = L.latLngBounds(coords);
      map.fitBounds(bounds);
    }
    return null;
  };

  const polygon = (raData?.area?.[0] || []); // Assuming we have only one polygon in the area array
  const points = (raData?.geometry || []);



  const renderMapModal = () => {
    
    
    if (!modalOpen) return null;
    
    return (
      <Draggable handle=".drag-handle" nodeRef={nodeRef}>
        <div ref={nodeRef} className="map-modal">
          <Paper  elevation={3} >
            <div className="drag-handle" style={{ cursor: 'move', padding: '10px' }}>
              <Typography variant='body1' style={{ fontWeight: 'bold' }}>{raData.name}</Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 5,
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            { 
              raData.area.length === 0
              ? <Typography variant='body1' style={{ fontStyle: 'italic', padding: 16}}>Ingen geometri funnet.</Typography>
              : <MapContainer center={[59.9168, 10.7356]} zoom={13} style={{ height: 200, width: "100%" }}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {polygon && <Polygon positions={polygon} />}
                  {points && points.type === 'Point' && 
                    <GeoJSON data={{
                      type: 'Feature',
                      geometry: points
                    }}
                    pointToLayer={(feature, latlng) => {
                      return L.marker(latlng, { icon: raLokIcon });
                    }} />
                  }
                  {polygon && <ChangeMapView coords={polygon} />}
                </MapContainer>
            }

            <div className="map-modal-text" >
              {
                raData.protectionType
                ? <Typography variant='body1' sx={{ fontStyle: 'italic'}}>{raData.protectionTypeLabel}: {raData.protectionType}</Typography>
                : <span />
              }
              <Typography 
                sx={{ fontStyle: 'normal'}}
                dangerouslySetInnerHTML={{ __html: raData.details.text }} // I'm trusting RA on this.. consider using const sanitizedHtml = DOMPurify.sanitize(myText);
                variant='body1'
              ></Typography>
            </div>
          </Paper>  
        </div>      
      </Draggable>
    );
  };

  return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0px', paddingRight: '4px' }}>
        <span className="map-modal-trigger" >{id}</span>
        <IconButton
          aria-label="Se detaljer"
          onClick={() => handleOpen(id) }
          edge="end"
          color={colour}
          style={{ padding:'0 8px 0 2px',  verticalAlign: 'top' }}
        >
          <PageviewIcon  style={{ fontSize: '22px' }} />
        </IconButton>        
        <IconButton
          aria-label="Åpne i Askeladden"
          onClick={() => window.open(`https://askeladden.ra.no/AskeladdenRedigering/#/lokalitetdetails/${id}`, '_blank')}
          edge="end"
          color={colour}
          style={{ padding: '2px', verticalAlign: 'top' }}
        >
          <LaunchIcon style={{ fontSize: '18px' }}  />
        </IconButton>
        {modalOpen && renderMapModal()}
      </div>  
  );
};

export function DraggableMap({ setLatLng }) {
  const [modalOpen, setModalOpen] = useState(false);
  const nodeRef = useRef(null);   

  const position = [60, 10]; // Default map center

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  function LocationMarker() {
    const map = useMapEvent('click', (e) => {
      // Leaflet provides the clicked latitude and longitude
      setLatLng(e.latlng);
      setModalOpen(false);    
    });
    return null; 
  };

  function LocPickMap() {
  
    if (!modalOpen) return null; 
    
    return (
        <Draggable handle=".drag-handle" nodeRef={nodeRef}>
          <div ref={nodeRef} className="map-modal">
            <Paper  elevation={3} >
              <div className="drag-handle" style={{ cursor: 'move', padding: '10px' }}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Velg sted på kart</Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 5,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <MapContainer center={position} zoom={7} style={{ height: '500px', width: 400 }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
              </MapContainer>
            </Paper>  
          </div>      
        </Draggable>    
    );  
  }

  return (
    <>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
        Velg i kart
      </Button>
      <LocPickMap />
    </>
  );
}
